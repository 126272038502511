<template>
    <Awards />
    <br>
    <Results />
</template>
    

<script>
import Awards from '../components/Awards.vue';
import Results from "@/components/2023Results.vue";


export default {
    components: {
        Awards,
        Results
    }
}
</script>
    